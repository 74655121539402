<template>
    <div class="panel has-text-weight-semibold">
            <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                <div class="columns is-mobile">
                    <div class="column is-size-6 has-text-centered">SHIFT REPORT</div>
                </div>
            </div>
            <div class="columns">
                <div class="column is-4 has-text-left">
                    <date-range-selector
                        :dateRange="salesDateRange"
                        @set-date-range="setDateRange"
                        >
                    </date-range-selector>
                </div>
                <div class="column is-6">
                    <line-chart 
                        :width="chartWidth"
                        :height="chartHeight"
                        :chart-data="leftChartData" 
                        :options="chartOptions">
                    </line-chart>
                </div>                
            </div>
        <div class="panel-heading has-text-weight-bold">
            <div class="columns">

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Cash Sales</p>
                    <p class="is-size-6" :class="{'has-text-danger': totalCashAmount < 0.00 }"> {{ totalCashAmount | formatCurrency }}</p>
                    </div>
                </div>  

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Actual Cash</p>
                    <p class="is-size-6" :class="{'has-text-danger': (totalCashActualOnhand + totalSafedropAmount)  < 0.00 }"> {{ totalCashActualOnhand + totalSafedropAmount | formatCurrency }}</p>
                    </div>
                </div> 

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Over/Short</p>
                    <p class="is-size-6" :class="{'has-text-danger': totalOverShort < 0.00 }"> {{ totalOverShort | formatCurrency }}</p>
                    </div>
                </div> 

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Deposits General</p>
                    <p class="is-size-6" :class="{'has-text-danger': totalDepositGeneral < 0.00 }"> {{ totalDepositGeneral | formatCurrency }}</p>
                    <!-- <p class="is-size-6"> {{ totalNetLottery | formatCurrency }}</p> -->
                    
                    </div>
                </div>

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Deposits Lottery</p>
                    <p class="is-size-6" :class="{'has-text-danger': totalDepositLottery < 0.00 }"> {{ totalDepositLottery | formatCurrency }}</p>
                    </div>
                </div>

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Withdrawals</p>
                    <p class="is-size-6" :class="{'has-text-danger': totalDepositATM < 0.00 }"> {{ totalDepositATM | formatCurrency }}</p>
                    </div>
                </div>

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Loaded in ATM</p>
                    <p class="is-size-6" :class="{'has-text-danger': totalLoadedInAtm < 0.00 }"> {{ totalLoadedInAtm | formatCurrency }}</p>
                    </div>
                </div>

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Onhand After Deposit</p>
                    <p class="is-size-6" :class="{'has-text-danger': totalPeriodEndCashOnhand < 0.00 }"> {{ totalPeriodEndCashOnhand | formatCurrency }}</p>
                    </div>
                </div>                                                                  
            </div>
        </div>
        <br>
        <b-table v-if="dailyGroup"
            class="has-text-weight-semibold"
            :visible="!isLoading"
            :data='dailyGroup'
            striped
            :scrollable="isScrollable"
            :paginated="isPaginated"
            :per-page="perPage"
            paginationPosition="both"
            sortIcon="arrow-up"
            sortIconSize="is-small"
            default-sort="salesDate"
            default-sort-direction="asc"
            :show-detail-icon="showDetailIcon"
            detailed
            detail-key="closingDate"
            @details-open="(row) => $buefy.toast.open(`Expanded ${row.salesDate}`)"            
            >

            <template> 
                <b-table-column numeric field="print" label="Print" align="right" v-slot="props">
                    <!-- <a link><img src="/images/printer.png" width="25" height="25" alt="" class="is-rounded"  @click="printDaysReport(props.row)"></a> -->
                    <b-button  title="Print" type="is-dark" outlined class="is-small is-hidden-mobile" @click="printDaysReport(props.row)">
                        <i class="fas fa-print"></i>
                    </b-button>
                </b-table-column>                    
                <b-table-column field="salesDate" label="Date" v-slot="props" sortable sorticon>
                    {{ props.row.salesDate.substr(0,10) | formatDate }}
                </b-table-column>
                <b-table-column numeric field="cashAmount" label="Cash Sales" v-slot="props" sortable sorticon>
                    {{ props.row.cashAmount | formatCurrency}}
                </b-table-column>
                <!-- <b-table-column numeric field="cashRefundAmount" label="Cash Refund" v-slot="props" sortable sorticon>
                    {{ props.row.cashRefundAmount | formatCurrency}}
                </b-table-column>  -->
                <b-table-column numeric field="netLottery" label="Net Lottery" v-slot="props" sortable sorticon>
                        {{ netLottery(props.row) | formatCurrency}}
                </b-table-column>                                
                <!-- <b-table-column numeric field="totalCashIncome" label="Cash Income" v-slot="props" sortable sorticon>
                    {{ props.row.totalCashIncome | formatCurrency}}
                </b-table-column> 
                <b-table-column numeric field="totalCashExpense" label="Cash Expense" v-slot="props" sortable sorticon>
                    {{ props.row.totalCashExpense | formatCurrency}}
                </b-table-column> -->
                <b-table-column numeric field="totalCashIncome" label="Cash Inc/Exp" v-slot="props" sortable sorticon>
                    <span :class="{'has-text-danger': (props.row.totalCashIncome - props.row.totalCashExpense) < 0.00 }">{{ props.row.totalCashIncome - props.row.totalCashExpense | formatCurrency}}</span>
                </b-table-column>
                <b-table-column numeric field="safedropCashAmount" label="Cash Safedrop" v-slot="props" sortable sorticon>
                    {{ props.row.safedropCashAmount | formatCurrency}}
                </b-table-column>                                  
                <b-table-column numeric field="cashActualOnhand" label="Cash Onhand" v-slot="props" sortable sorticon>
                    {{ props.row.cashActualOnhand | formatCurrency}}
                </b-table-column> 
                <b-table-column numeric field="cashOverShort" label="Over Short" v-slot="props" sortable sorticon>
                    <span :class="{'has-text-danger': props.row.cashOverShort < 0.00 }">{{ props.row.cashOverShort | formatCurrency }}</span>
                </b-table-column>                    
                <b-table-column numeric field="bankDepositGeneral" label="Deposit General" v-slot="props" sortable sorticon>
                    {{ props.row.bankDepositGeneral | formatCurrency}}
                </b-table-column>
                <b-table-column numeric field="bankDepositLottery" label="Deposit Lottery" v-slot="props" sortable sorticon>
                    {{ props.row.bankDepositLottery | formatCurrency}}
                </b-table-column>
                <b-table-column numeric field="bankDepositAtm" label="Cash Withdraw" v-slot="props" sortable sorticon>
                    {{ props.row.bankDepositAtm | formatCurrency}}
                </b-table-column>  
                <b-table-column numeric field="cashLoadedInAtm" label="Loaded ATM." sortable sorticon v-slot="props">
                    {{ props.row.cashLoadedInAtm | formatCurrency}}
                </b-table-column>                                              
                <b-table-column numeric field="netCashOnHand" label="Onhand After Deposit" v-slot="props" sortable sorticon>
                    {{ props.row.netCashOnHand | formatCurrency}}
                </b-table-column> 
            </template>

            <template slot="detail" slot-scope="props">
              <b-table :data="props.row.shifts">
                <template>
                    <b-table-column label="Shift" v-slot="props" >
                         
                        <b-tooltip position="is-right" class="is-hidden-mobile" animated multilined>
                            {{ props.index + 1 }} 🕘 
                            <template v-slot:content>
                                <div class="panel">
                                    <p class="is-4 has-text-weight-bold">SHIFT {{ props.index + 1 }}</p>
                                    <p><b>Start</b>: {{ props.row.periodBeginDate | formatDateTime }}</p>
                                    <p><b>&nbsp;&nbsp;End</b>: {{ props.row.periodEndDate | formatDateTime }}</p>
                                </div>
                            </template>
                        </b-tooltip>
                        <b-tooltip position="is-left" class="is-hidden-desktop" animated multilined>
                            {{ props.index + 1 }} 🕘 
                            <template v-slot:content>
                                <div class="panel">
                                    <p class="is-4 has-text-weight-bold">SHIFT {{ props.index + 1 }}</p>
                                    <p><b>Start</b>: {{ props.row.periodBeginDate | formatDateTime }}</p>
                                    <p><b>&nbsp;&nbsp;End</b>: {{ props.row.periodEndDate | formatDateTime }}</p>
                                </div>
                            </template>
                        </b-tooltip>
                    </b-table-column>
                    <b-table-column numeric field="cashAmount" label="Cash Sales" v-slot="props">
                        {{ props.row.cashAmount |formatCurrency}}
                    </b-table-column>
                    <b-table-column numeric field="cashRefundAmount" label="Cash Refund" v-slot="props" sortable sorticon>
                        {{ props.row.cashRefundAmount | formatCurrency}}
                    </b-table-column>                                         
                    <b-table-column numeric field="netLottery" label="Net Lottery" v-slot="props" sortable sorticon>
                        {{ netLottery(props.row) | formatCurrency}}
                    </b-table-column>
                    <b-table-column numeric field="totalCashIncome" label="Cash Income" v-slot="props">
                        {{ props.row.totalCashIncome |formatCurrency}}
                    </b-table-column>
                    <b-table-column numeric field="totalCashExpense" label="Cash Expense" v-slot="props">
                        {{ props.row.totalCashExpense |formatCurrency}}
                    </b-table-column>
                    <b-table-column numeric field="safedropCashAmount" label="Cash Safedrop" v-slot="props">
                        {{ props.row.safedropCashAmount |formatCurrency}}
                    </b-table-column>                    
                    <b-table-column numeric field="cashActualOnhand" label="Cash Onhand" v-slot="props">
                        {{ props.row.cashActualOnhand |formatCurrency}}
                    </b-table-column>
                    <b-table-column numeric field="cashOverShort" label="Over/Short" v-slot="props" sortable sorticon>
                    <span :class="{'has-text-danger': props.row.cashOverShort < 0.00 }">{{ props.row.cashOverShort | formatCurrency}}</span>
                    </b-table-column>
                    <b-table-column numeric field="bankDepositGeneral" label="Deposit General" v-slot="props" sortable sorticon>
                        {{ props.row.bankDepositGeneral | formatCurrency}}
                    </b-table-column>
                    <b-table-column numeric field="bankDepositLottery" label="Deposit Lottery" v-slot="props" sortable sorticon>
                        {{ props.row.bankDepositLottery | formatCurrency}}
                    </b-table-column>
                    <b-table-column numeric field="bankDepositAtm" label="Cash Withdrawal" v-slot="props" sortable sorticon>
                        {{ props.row.bankDepositAtm | formatCurrency}}
                    </b-table-column>
                    <b-table-column numeric field="cashLoadedInAtm" label="Loaded ATM." sortable sorticon v-slot="props">
                        {{ props.row.cashLoadedInAtm | formatCurrency}}
                    </b-table-column>
                    <b-table-column numeric field="netCashOnHand" label="Onhand After Deposit" v-slot="props" sortable sorticon>
                        {{ props.row.netCashOnHand | formatCurrency}}
                    </b-table-column>                                                                                                                                             
                </template>
              </b-table>

            </template>
            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>
        </b-table>
        <shift-report-print
            :shiftsData="shiftsData.shifts"
            :isShowDialog="isPrintPage" 
            @hide-dialog="hidePrintPage">
        </shift-report-print>
        <shift-detail-report-pdf
            ref="pdfReport"
            :isShowPDFDialog="isShowPDFDialog"
            :shiftDate="shiftDate"
            :hideZeroShift="hideZeroShift"
            @close-pdf-dialog="togglePDFDialog"
        >
        </shift-detail-report-pdf>

    </div>  
</template>

<script>


import DateRangeSelector from "../../../components/app/DateRangeSelector"
import UserStore from "../../../store/UserStore"
import DataLoading from '../../../components/app/DataLoading'
import LineChart from '../dashboard/LineChart.js'
import moment from 'moment'
import ShiftReportPrint from './ShiftReportPrint'
import ShiftDetailReportPdf from './ShiftDetailReportPDF'

import axios from 'axios'

export default {

    components: {
        DataLoading,
        ShiftReportPrint,
        LineChart,
        DateRangeSelector,
        ShiftDetailReportPdf
    },

    metaInfo: {
        title: 'Shift Details'
    },

    data() {
        return {
            leftChartData: {},
            chartWidth: 150,
            chartHeight: 250,
            chartOptions: {
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                },
                responsive: true,
                maintainAspectRatio: false
            },
            chartItems: [
                {"field": "cashAmount", "label": "Cash Sales", "color": "#40E0D0"},
                {"field": "safedropCashAmount", "label":"Safe Drop", "color": "#FF7F50"},
                {"field": "cashActualOnhand", "label": "Cash Onhand", "color": "#6495ED"},
                {"field": "cashOverShort", "label": "Cash Over/Short", "color": "#f1c40f"},
                {"field": "bankDepositGeneral", "label": "Deposit-General", "color": "#338444"},
                {"field": "bankDepositLottery", "label": "Deposit-Lottery", "color": "#A52A2A"},
                {"field": "bankDepositAtm", "label": "Withdrawals", "color": "#1E90FF"},
                {"field": "cashLoadedInAtm", "label": "Loaded ATM", "color": "#2E9511"},
                {"field": "netCashOnHand", "label": "Net Onhand", "color": "#FF00FF"},
            ],
            salesDateRange: [],
            isLoading: true,
            isFullPage: true,
            isScrollable: true,
            isPaginated: true,
            showDetailIcon: true,
            perPage: 50,
            isShowPDFDialog: false,
            dailyShifts: [],
            dailyGroup: [],
            minDate: new Date(2016, 8, 1),
            maxDate: new Date(),
            month: new Date(),
            shiftDate: new Date(),
            shiftDateString: "",
            hideZeroShift: true,
            isPrintPage: false,
            shiftsData: {},
            netCashOverShort: 0,
            totalCashAmount: 0,
            totalSafedropAmount: 0,
            totalCashActualOnhand: 0,
            totalOverShort: 0,
            totalDepositGeneral: 0,
            totalDepositLottery: 0,
            totalDepositATM: 0,
            totalLoadedInAtm: 0,
            totalNetLottery: 0,
            totalPeriodEndCashOnhand: 0,
            tableNumericFields: [
                "netSales",
                "merchSales",
                "fuelSales",
                "cashOverShort",
                "cashAmount",
                "totalCashIncome",
                "totalCashExpense",
                "cashActualOnhand",
                "bankDepositGeneral",
                "bankDepositLottery",
                "bankDepositAtm",
                "cashLoadedInAtm",
                "safedropAmount",
                "safedropCashAmount",
                "cashRefundAmount",
                "onlineLottoSales",
                "onlineLottoPayout",
                "instantLottoSales",
                "instantLottoPayout",
                "netLottery",
                "lotteryMachineWithdraw",
                "onlineLotteryCanceled",

            ],
            salesChart: {
                    labels: [],
                    datasets: [],
                    options: []
                },
            tableDetailColumns: [
                {
                        field: `netSales`,
                        label: 'Net Sales',
                        width: '40',
                        numeric: true
                },
                {
                        field: 'netSales',
                        label: 'Merch',
                        width: '40',
                        numeric: true
                },
                {
                        field: `fuelSales`,
                        label: 'Fuel Sales',
                        width: '40',
                        numeric: true
                },
                {
                        field: 'cashAmount',
                        label: 'Cash',
                        width: '40',
                        numeric: true
                },
                {
                        field: `totalCashIncome`,
                        label: 'Cash Income',
                        width: '40',
                        numeric: true
                },
                {
                        field: 'totalCashExpense',
                        label: 'Cash Expense',
                        width: '40',
                        numeric: true
                },
                {
                        field: `cashActualOnhand`,
                        label: 'Cash Onhand',
                        width: '40',
                        numeric: true
                },
                {
                        field: 'cashOverShort',
                        label: 'Over/Short',
                        width: '40',
                        numeric: true
                },                                    
            ]
        }
    },

    methods: {

        fetchShiftDetails() {
            if (UserStore.getters.getActiveStore) {
                this.dailyShifts = []
                this.isLoading = true
                this.dailyGroup = []
                var startDate = this.salesDateRange[0].toISOString().split('T')[0] 
                var endDate = this.salesDateRange[1].toISOString().split('T')[0]

                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + "/sales/shifts/net?size=50"  + "&fromDate="+startDate+"&toDate="+endDate

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        
                        this.dailyShifts = response.data.data.sort(function(a,b){
                            return new Date(a.periodBeginDate) - new Date(b.periodBeginDate)
                            })
                        this.groupedByDate()
                        this.calculateTotals()
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        // console.log("Failed")
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
                        if (err.response) {
                            if (err.response.status === 403) {
                                alert('Session Expired. Please log in again!')
                                UserStore.commit('userLogout')
                            }
                            if (err.response.status === 404 || err.response.status === 400) {
                                this.$buefy.toast.open({
                                    message: 'No shift data found',
                                    type: 'is-warning'
                                })
                            }
                        }
                        this.isLoading = false                     
                    })
                    
            }
        },

        groupedByDate() {

            var sortedDailyShifts = this.dailyShifts.sort((a,b) =>  b.periodBeginDate-a.periodBeginDate )

            sortedDailyShifts.forEach((item, index) => {
                item.shiftOrder = index
                var prevCashOnHand = 0
                if (index > 0) {
                    prevCashOnHand = sortedDailyShifts[index-1].netCashOnHand
                }
                item.netCashOnHand = prevCashOnHand +
                                item.safedropCashAmount +
                                item.cashActualOnhand -
                                item.bankDepositGeneral -
                                item.bankDepositLottery -
                                item.bankDepositAtm
            })
            
            this.validSales =  sortedDailyShifts.filter(e => e.netSales !== null)
            this.dailyGroup = Object.values(this.validSales.reduce((a,{closingDate, ...props}) => {
                if(!a[closingDate]) {
                    a[closingDate]  = Object.assign({}, {closingDate: props,shifts : [props]});
                    a[closingDate].netSales = 0.00
                    a[closingDate].merchSales = 0.00
                    a[closingDate].fuelSales = 0.00
                    a[closingDate].cashAmount = 0.00
                    a[closingDate].totalCashIncome = 0.00
                    a[closingDate].totalCashExpense = 0.00
                    a[closingDate].cashActualOnhand = 0.00
                    a[closingDate].cashOverShort = 0.00
                    a[closingDate].bankDepositGeneral = 0.00
                    a[closingDate].bankDepositLottery = 0.00
                    a[closingDate].bankDepositAtm = 0.00
                    a[closingDate].cashLoadedInAtm = 0.00
                    a[closingDate].safedropAmount = 0.00
                    a[closingDate].safedropCashAmount = 0.00
                    a[closingDate].cashRefundAmount = 0.00
                    a[closingDate].netCashOnHand = 0.00
                    a[closingDate].netLottery = 0.00
                    a[closingDate].instantLottoSales = 0.00
                    a[closingDate].instantLottoPayout = 0.00
                    a[closingDate].onlineLottoSales = 0.00
                    a[closingDate].onlineLotteryCanceled = 0.00
                    a[closingDate].onlineLottoPayout = 0.00
                    a[closingDate].lotteryMachineWithdraw = 0.00
                    // a[closingDate].salesDate = new Date(closingDate)
                    a[closingDate].salesDate = String(closingDate[1]).padStart(2, '0')+'/'+String(closingDate[2]).padStart(2, '0')+'/'+closingDate[0]
                } else {
                    if (props.netSales != null) {
                        a[closingDate].shifts.push(props);
                    }
                }
                this.tableNumericFields.forEach(function (element) {
                    if (props[element] === null) {
                        props[element] = 0.00
                    } else {
                        a[closingDate][element] += parseFloat(props[element])
                    }
                })

                return a;
            },{}));
            
            this.dailyGroup.forEach((item, index) => {
                item.shiftOrder = index
                var prevCashOnHand = 0
                if (index > 0) {
                    prevCashOnHand = this.dailyGroup[index-1].netCashOnHand
                }
                item.netCashOnHand = prevCashOnHand +
                                item.safedropCashAmount +
                                item.cashActualOnhand -
                                item.bankDepositGeneral -
                                item.bankDepositLottery -
                                item.bankDepositAtm - 
                                item.cashLoadedInAtm
            })
        },

        totalArrayColumn(array) {
            return array.reduce((a, b) => {
                return a + b.netSales;
            }, 0);
        },


        hidePrintPage() {
            this.shifts = {}
            this.isPrintPage = false
        },

        showPrintPage(row) {
            this.shiftsData = row
            this.isPrintPage = true
        },

        setDateRange(dateRange) {
            this.salesDateRange = [dateRange[0], dateRange[1]]
        },

        netOverShort(row) {
            return ((row.cashActualOnhand + 
                    row.safedropCashAmount) - 
                    (row.cashAmount - 
                    row.cashRefundAmount + 
                    row.totalCashIncome - 
                    row.totalCashExpense)).toFixed(2)            
        },

        setTotalCashAmount() {
            this.totalCashAmount = this.dailyGroup.reduce((accum,item) => accum + 
                    parseFloat(item.cashAmount - 
                    parseFloat(item.cashRefundAmount) +
                    // parseFloat(item.netLottery) +
                    parseFloat(item.totalCashIncome) -
                    parseFloat(item.totalCashExpense) 
                ), 0)
        },

        setTotalSafedropAmount() {
            this.totalSafedropAmount = this.dailyGroup.reduce((accum,item) => accum + parseFloat(item.safedropCashAmount ? item.safedropCashAmount : 0.00), 0)
        },

        setTotalNetLottery() {
            
            this.totalNetLottery = this.dailyGroup.reduce((accum,item) => accum + 
                parseFloat(item.instantLottoSales ? item.instantLottoSales : 0.00) -
                parseFloat(item.instantLottoPayout ? item.instantLottoPayout : 0.00) + 
                parseFloat(item.onlineLottoSales ? item.onlineLottoSales : 0.00) -
                parseFloat(item.onlineLotteryCanceled ? item.onlineLotteryCanceled : 0.00) -
                parseFloat(item.onlineLottoPayout ? item.onlineLottoPayout : 0.00) -
                parseFloat(item.lotteryMachineWithdraw ? item.lotteryMachineWithdraw : 0.00)
            , 0)
        },

        setTotalCashActualOnhand() {
            this.totalCashActualOnhand = this.dailyGroup.reduce((accum,item) => accum + parseFloat(item.cashActualOnhand ? item.cashActualOnhand : 0.00), 0)
        },

        setTotalOverShort() {
            this.totalOverShort = this.dailyGroup.reduce((accum,item) => accum + parseFloat(item.cashOverShort ? item.cashOverShort : 0.00), 0)
        },

        setTotalDepositGeneral() {
            this.totalDepositGeneral = this.dailyGroup.reduce((accum,item) => accum + parseFloat(item.bankDepositGeneral ? item.bankDepositGeneral : 0.00), 0)
        },

        setTotalDepositLottery() {
            this.totalDepositLottery = this.dailyGroup.reduce((accum,item) => accum + parseFloat(item.bankDepositLottery ? item.bankDepositLottery : 0.00), 0)
        },

        setTotalDepositATM() {
            this.totalDepositATM = this.dailyGroup.reduce((accum,item) => accum + parseFloat(item.bankDepositAtm ? item.bankDepositAtm : 0.00), 0)
        },

        setTotalLoadedInAtm() {
            this.totalLoadedInAtm = this.dailyGroup.reduce((accum,item) => accum + parseFloat(item.cashLoadedInAtm ? item.cashLoadedInAtm : 0.00), 0)
        },

        setTotalPeriodEndCashOnhand() {
            this.totalPeriodEndCashOnhand = this.totalSafedropAmount + 
                    this.totalCashActualOnhand -
                    this.totalDepositGeneral -
                    this.totalDepositLottery -
                    this.totalDepositATM -
                    this.totalLoadedInAtm
        },

        shiftNetCashOnHand(row) {
            
            return parseFloat(row.cashActualOnhand ? row.cashActualOnhand : 0.00) +
                    parseFloat(row.safedropCashAmount ? row.safedropCashAmount : 0.00) - 
                    parseFloat(row.bankDepositGeneral ? row.bankDepositGeneral : 0.00) - 
                    parseFloat(row.bankDepositLottery ? row.bankDepositLottery : 0.00) - 
                    parseFloat(row.bankDepositAtm ? row.bankDepositAtm : 0.00) - 
                    parseFloat(row.cashLoadedInAtm ? row.cashLoadedInAtm : 0.00)
        },

        netLottery(row) {

            return (parseFloat(row.instantLottoSales ? row.instantLottoSales : 0.00) -
                    parseFloat(row.instantLottoPayout ? row.instantLottoPayout : 0.00) +
                    parseFloat(row.onlineLottoSales ? row.onlineLottoSales : 0.00) -
                    parseFloat(row.onlineLotteryCanceled ? row.onlineLotteryCanceled : 0.00) -
                    parseFloat(row.onlineLottoPayout ? row.onlineLottoPayout : 0.00) -
                    parseFloat(row.lotteryMachineWithdraw ? row.lotteryMachineWithdraw : 0.00) 
                    ).toFixed(2)
        },

        calculateTotals() {
            this.setTotalCashAmount()
            this.setTotalNetLottery()
            this.setTotalSafedropAmount()
            this.setTotalCashActualOnhand()
            this.setTotalOverShort()
            this.setTotalDepositGeneral()
            this.setTotalDepositLottery()
            this.setTotalDepositATM()
            this.setTotalLoadedInAtm()
            this.setTotalPeriodEndCashOnhand()

            this.salesChart = this.drawLeftChart()
        },

        drawLeftChart() {

            this.datasets = []
            var data = []
            var labels = []
            this.dailyGroup.forEach( day => {
                labels.push(day.closingDate.date)
            })
            this.chartItems.forEach( item => {
                
                data = []
                this.dailyGroup.forEach( day => {
                    data.push(day[item.field].toFixed(2))
                })

                this.datasets.push({
                    label: item.label,
                    data: data,
                    fill: false,
                    borderColor: item.color,
                    backgoundColor: item.color

                })                
            })
            this.leftChartData = {
                labels: labels,
                datasets: this.datasets,
                options: this.chartOptions
            }

        }, 

        async setReportDate(row) {
            this.shiftDate = moment(row.closingDate.date, 'MM-DD-YYYY').toDate()
        },

        async printDaysReport(row) {
            await this.setReportDate(row)
                    .then(() => {
                        this.togglePDFDialog()
                    })
            
        },
        
        async togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
            if (this.isShowPDFDialog) {
                await this.$refs.pdfReport.loadData()
            }
        },        

     },

    watch: {
        salesDateRange: function() {
            if (UserStore.getters.getActiveStore.role != 'STAFF') {
                this.fetchShiftDetails()
            }
        }       

    },
    
    mounted() {

        var today = new Date()

        if (today.getDate() == 1) {
            today.setMonth(today.getMonth() - 1)
        }
        var firstDay=new Date(today.getFullYear(), today.getMonth(), 1)
        var lastDay = new Date(today.getFullYear(), today.getMonth()+1, 0)
        this.salesDateRange=[firstDay, lastDay]      
    }    
}
</script>

<style>
.detailTable {
    background-color: rebeccapurple;
}
</style>