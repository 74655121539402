<template>
<article class="columns">
  <section class="column">
    <div class="modal" style="width: auto" v-bind:class="{ 'is-active': isShowDialog }">
        <div class="modal-background"></div>
        <div class="modal-card scrollable" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Daily Summary Report</p>
                <button
                    type="button"
                    class="delete"
                    @click="$emit('hide-dialog')"/>
            </header>
            <section class="modal-card-body">

                <div class="is-size-4 has-text-centered" v-if="shiftsData">Date {{ shiftsData[0].date }}</div>
                <b-table v-if="salesFieldsArray"
                    class="has-text-weight-semibold"
                    :visible="!isLoading"
                    :data='salesFieldsArray'
                    striped
                    :scrollable="isScrollable"
                    :paginated="isPaginated"
                    :per-page="perPage"
                    paginationPosition="both"
                    sortIcon="arrow-up"
                    sortIconSize="is-small"
                    default-sort="salesDate"
                    default-sort-direction="asc"
                    >
                    <template>

                        <b-table-column field="name" label="" v-slot="props">
                            {{ salesFieldLabel(props.row.name) }}
                        </b-table-column>
                        <b-table-column numeric field="shift0" label="Shift 1" v-slot="props">
                            {{ fieldFormat(props.row.shift0) }}
                        </b-table-column>
                        <b-table-column numeric field="shift1" label="Shift 2" v-slot="props">
                            {{ fieldFormat(props.row.shift1) }}
                        </b-table-column>
                        <b-table-column numeric field="shift1" label="Shift 3" v-slot="props">
                            {{ fieldFormat(props.row.shift2) }}
                        </b-table-column>
                        <b-table-column numeric field="total" label="Total" v-slot="props">
                            {{ fieldFormat(props.row.total) }}
                        </b-table-column> 
  
                                                         
                    </template>
                </b-table>
            </section>
            <footer class="modal-card-foot">
                <b-button
                    label="Close"
                    @click="$emit('hide-dialog')" />
            </footer>
        </div>
    </div>
  </section>
</article>
</template>

<script>
export default {

    props: {
        isShowDialog: Boolean,
        shiftsData: []
    },

    data() {
        return {
            email: "aby@tsits.com",
            password: "Test123",
            isLoading: true,
            isFullPage: true,
            isScrollable: true,
            isPaginated: false,
            perPage: 50,
            shiftsAsColumns: [],
            numberFields: ["netSales","insideSales","merchSales","fuelSales","totalPaymentOut","cashAmount","cashActualOnhand","cashOverShort","safedropAmount","totalSafeDrop","cashFlow","totalCashExpense","totalNoncashExpense","totalCashIncome","totalNoncashIncome","previousCashUsed","cashLoadedInAtm","bankDepositGeneral","bankDepositLottery","bankDepositAtm","cashInvestorDrawn","cashAdjustment","carWashSold","phoneCardSold","onlineLottoSales","onlineLottoPayout","instantLottoSales","instantLottoPayout","cigaretteOpenCount","cigaretteBuyCount","cigaretteCloseCount","cigaretteBuyAmount","cigaretteSoldAmount"],
            salesFields: [
                    {"name": "netSales", "label": "Net Sales"},
                    {"name": "insideSales", "label": "Inside Sales"},
                    {"name": "merchSales", "label": "Merchandise Sales"},
                    {"name": "fuelSales", "label": "Fuel Sales"},
                    {"name": "totalPaymentOut", "label": "Total Payment Out"},
                    {"name": "cashAmount", "label": "Cash Amount"},
                    {"name": "cashActualOnhand", "label": "Actual Cash On Hand"},
                    {"name": "cashOverShort", "label": "Cash Over/Short"},
                    {"name": "safedropAmount", "label": "Safe Drop Amount"},
                    {"name": "totalSafeDrop", "label": "Total Safe Drop"},
                    // {"name": "cashFlow", "label": "Cash Flow"},
                    {"name": "totalCashExpense", "label": "Total Cash Expense"},
                    {"name": "totalNoncashExpense", "label": "Total Non-Cash Expense"},
                    {"name": "totalCashIncome", "label": "Total Cash Income"},
                    {"name": "totalNoncashIncome", "label": "Total Non-Cash Income"},
                    // {"name": "previousCashUsed", "label": "Previous Cash Used"},
                    {"name": "cashLoadedInAtm", "label": "Cash Loaded to ATM"},
                    {"name": "bankDepositGeneral", "label": "Bank Deposit - General"},
                    {"name": "bankDepositLottery", "label": "Bank Deposit - Lottery"},
                    {"name": "bankDepositAtm", "label": "Bank Deposit - ATM"},
                    // {"name": "cashInvestorDrawn", "label": "Cash Drawn by Investors"},
                    // {"name": "cashAdjustment", "label": "Cash Adjustments"},
                    // {"name": "carWashSold", "label": "Car Was Sold"},
                    // {"name": "phoneCardSold", "label": "Phone Card Sold"},
                    ],
            cigaretteFields: ["cigaretteOpenCount","cigaretteBuyCount","cigaretteCloseCount","cigaretteBuyAmount","cigaretteSoldAmount"],
            lotteryFields: ["onlineLottoSales","onlineLottoPayout","instantLottoSales","instantLottoPayout"],           
        }
    },

    methods: {

        shiftsToColumn() {
            
            this.shiftsAsColumns = []
            const keys = Object.keys(this.shiftsData[0])
            keys.forEach( field => {
                
                var shift = {}
                
                shift["name"] = field
                var total = 0.00
                this.shiftsData.forEach((element,index) => {
                    
                    if (this.numberFields.indexOf(field) > -1) {
                        
                        if (element[field] == null) {
                            element[field] = 0.00
                        }
                        total = total + element[field]

                    }
                                      
                    shift["shift"+index] = element[field]
                    
                })
                if (this.shiftsData.length < 3) {
                    shift["shift2"] = 0.00
                }
                shift["total"] = total
                this.shiftsAsColumns.push(shift)
            })

        },       

        fieldFormat(field) {
            if (!isNaN(field)) {
                const currencyFormatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2
                    })
                return currencyFormatter.format(field)
            } else {
                return field
            }
        },

        salesFieldLabel(field) {
            
            var temp = this.salesFields.find(item => item.name === field)
            if (temp) {
                return temp.label
            } else {
                return field
            }
        },       

    },

    computed: {
        salesFieldsArray() {
            // return this.shiftsAsColumns.filter(item => this.salesFields.some(field => field.name === item.name && item.total !== 0.00))
            return this.shiftsAsColumns.filter(item => this.salesFields.some(field => field.name === item.name))
        },

    },

    watch: {
        shiftsData: function() {
            this.shiftsToColumn()
        }
    },

}
</script>